export const investmentTypes = {
  order: 2,
  name: "Investment types",
  keywords:
    "deal sale financing senior junior mezzanine partial recap foreclose assume equity payoff retire defease defeasance note loan",
  subcategory: "Investments",
  markdown: `# Investment types

  Tower Hunt supports many common {% inlineRouterLink articleId="the-role-of-investments" %}investment{% /inlineRouterLink %} scenarios. Below are "before" and "after" {% inlineRouterLink articleId="understand-the-capital-stack" %}capital stacks{% /inlineRouterLink %} for each.

  {% callout type="info" %}
  **Note:** These examples focus on the important capital stack changes caused by each type. Please keep in mind that, in practice, each example can present in a variety of ways (ex. with or without debt, single or multiple owners, etc.).
  {% /callout %}

  ## Outright sale

  When an ownership group sells its entire position to a new group, it is often called an *outright sale*.

  {% beforeAfterCapitalStack investmentType="outrightSale" /%}

  ### Steps to use

  {% tablessContainer %}
  1. Click the {% inlineAppIcon iconName="arrowsLeftRight" /%}**Deal actions menu** that appears at the top-right of the equity layer on the "before" side of the capital stack.
  2. Click {% inlineAppIcon iconName="dollar" /%}**Sell**.
  ![Outright sale GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}

  ### Assuming existing debt

  Some loans can be assumed, allowing an equity buyer to take over the borrower's responsibilities with the existing lender.

  {% beforeAfterCapitalStack investmentType="assumeExistingDebt" /%}

  ### Steps to use

  {% tablessContainer %}
  1. First, trigger an outright sale. Do not save it yet.
  2. From the "before" side of the {% inlineRouterLink articleId="deal-hub" %}Deal Hub{% /inlineRouterLink %} capital stack, click the {% inlineAppIcon iconName="arrowsLeftRight" /%}**Deal actions menu** that appears at the top-right of the existing loan layer.
  3. Click {% inlineAppIcon iconName="rightArrow" /%}**Assume loan**.
  4. The existing loan now appears on the "after" side of the capital stack.
  5. Save the outright sale investment. The existing loan is not retired.
  ![Loan assumption GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}

  ## Partial interest sale

  When less than 100% of the ownership is sold, it is often called a *partial interest sale* or *recapitalization* (recap). There are two common scenarios: when a single owner brings in a partner and when a partner sells its interest to a new partner.

  ### Bringing in a partner

  This example shows a single owner selling part of its interest to a new partner.

  {% beforeAfterCapitalStack investmentType="partialInterestNewPartner" /%}

  It is also possible for an existing ownership group to bring in an additional partner.

  {% beforeAfterCapitalStack investmentType="partialMultiInterestNewPartner" /%}

  ### Steps to use

  {% tablessContainer %}
  1. Click the {% inlineAppIcon iconName="arrowsLeftRight" /%}**Deal actions menu** that appears to the right of a company on the "before" side of the capital stack.
  2. Click {% inlineAppIcon iconName="partialInterest" /%}**Sell partial interest**.
  3. **Optional**: If multiple owners are participating in the partial interest sale, repeat steps 1 & 2 for each additional seller **while editing the new investment in the "after" side of the capital stack**.
  ![Partial interest net new sale GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}

  ### Replacing a partner

  Sometimes, one partner will remain in the capital stack while another sells its interest to a new investor.

  {% beforeAfterCapitalStack investmentType="partialInterestReplacePartner" /%}

  ### Steps to use

  {% tablessContainer %}
  1. Click the {% inlineAppIcon iconName="arrowsLeftRight" /%}**Deal actions menu** that appears to the right of a company on the "before" side of the capital stack.
  2. Click {% inlineAppIcon iconName="partialInterest" /%}**Sell partial interest**.
  ![Partial interest replacement sale GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}

  ## Loan origination

  As the name suggests, this investment type results in a new loan being placed on the asset. This is also called a *financing*. Loans have different levels of *seniority* that correspond to their position in the stack. The bottom-most layer (first to be repaid) is the *senior* loan.

  {% beforeAfterCapitalStack investmentType="loanOrigination" /%}

  ### Steps to use

  {% tablessContainer %}
  1. When no other loans are present in the capital stack, click the **+ Loan** button that appears at the bottom of the equity layer to begin adding a new senior loan.
  ![Senior loan origination sale GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}

  ### Junior loans

  Each debt layer above the senior is called a *junior* (or *mezzanine*) loan. The name is less important than knowing the position in the stack. These are just a few examples:

  {% beforeAfterCapitalStack investmentType="juniorLoanOrigination" /%}

  **Or**

  {% beforeAfterCapitalStack investmentType="juniorLoanOrigination2" /%}

  **Or**

  {% beforeAfterCapitalStack investmentType="juniorLoanOrigination3" /%}

  ### Steps to use

  {% tablessContainer %}
  1. When a senior loan is present, click the **+ Loan** button that appears between layers to begin adding a new junior loan.
  ![Senior loan origination sale GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}

  ## Refinancing

  When existing loans are paid off (*retired*) and replace by a new loan, it is called a *refinancing*. Generally, all existing debt is retired during a refinancing. The example below shows a senior and junior loan being replaced by a new senior loan.

  {% beforeAfterCapitalStack investmentType="refinancing" /%}

  ### Steps to use

  {% tablessContainer marginStyles="mb-4" %}
  1. Click the {% inlineAppIcon iconName="arrowsLeftRight" /%}**Deal actions menu** that appears at the top-right of the **equity layer** on the "before" side of the capital stack.
  2. Click {% inlineAppIcon iconName="circularArrows" /%}**Refinance**.
  ![Refinancing GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}

  {% callout type="tip" %}
  **Tip:** If the refinancing places multiple layers of debt (senior and junior), save the new senior loan first and then add junior loans to the updated capital stack {% inlineRouterLink articleId="create-an-investment" %}using the same closing date{% /inlineRouterLink %}.
  {% /callout %}

  ## Loan payoff / defeasance

  Occasionally, a loan is paid off (*retired*) and not replaced. Depending upon the type of loan, this can be called *defeasance*. Regardless of the name, in Tower Hunt the result is that the loan gets removed from the capital stack.

  {% beforeAfterCapitalStack investmentType="retireLoan" /%}

  ### Steps to use

  {% tablessContainer marginStyles="mb-4" %}
  1. Click the {% inlineAppIcon iconName="arrowsLeftRight" /%}**Deal actions menu** that appears at the top-right of the **debt layer** on the "before" side of the capital stack.
  2. Click {% inlineAppIcon iconName="cash" /%}**Retire / Defease**.
  ![Retire loan GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}

  {% callout type="caution" %}
  **Important:** {% inlineRouterLink articleId="investment-types" sectionName="Refinancing" %}Refinancings{% /inlineRouterLink %} and {% inlineRouterLink articleId="investment-types" sectionName="Outright sale" %}outright sales{% /inlineRouterLink %} **automatically** retire any existing debt. So, it is only necessary to manually retire a loan if no other investment is taking place.
  {% /callout %}

  ## Loan sale

  Loans (specifically, the *notes*) are sold for various reasons. In Tower Hunt, each loan is an asset with its own {% inlineRouterLink articleId="understand-the-capital-stack" sectionName="Nested capital stacks" %}nested capital stack{% /inlineRouterLink %}. This makes it a bit easier to understand what is happening: the equity layer of the loan is being sold to a new investor, as in an {% inlineRouterLink articleId="investment-types" sectionName="Outright sale" %}outright sale{% /inlineRouterLink %}.

  {% beforeAfterCapitalStack investmentType="loanOutrightSale" /%}

  ### Steps to use

  {% tablessContainer marginStyles="mb-4" %}
  1. Click the {% inlineAppIcon iconName="arrowsLeftRight" /%}**Deal actions menu** that appears at the top-right of the **debt layer** on the "before" side of the capital stack.
  2. Click {% inlineAppIcon iconName="arrowsLeftRight" /%}**Sell**.
  ![Retire loan GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}

  ## Foreclosure

  When a borrower fails to meet its loan obligations, its lender can pursue foreclosure. While there are many varieties of and legal complexities with foreclosure, **Tower Hunt is really only focused on the ultimate outcome**: the borrower and the defaulted loan are removed from the capital stack and a new owner comes in. Sometimes, the new owner was the lender on the loan:

  {% beforeAfterCapitalStack investmentType="foreclosure" /%}

  Sometimes, the lender quickly sells the equity position to another investor:

  {% beforeAfterCapitalStack investmentType="foreclosure2" /%}

  The preferred way to model this depends on when you become aware of the situation:
  - If the former lender has taken control of the asset and may or may not sell it, we recommend entering the lender as the new owner.
  - If a third party has already acquired the asset from the lender (especially if that process happened quickly), we recommend entering the third party as the new owner.

  ### Steps to use

  {% tablessContainer marginStyles="mb-4" %}
  1. Click the {% inlineAppIcon iconName="arrowsLeftRight" /%}**Deal actions menu** that appears at the top-right of the **debt layer** on the "before" side of the capital stack.
  2. Click {% inlineAppIcon iconName="key" /%}**Foreclose**.
  ![Retire loan GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}

  {% callout type="tip" %}
  **Tip:** If the former lender is entered as the new owner, you can easily model a subsequent {% inlineRouterLink articleId="investment-types" sectionName="Outright sale" %}outright sale{% /inlineRouterLink %} if/when that happens.
  {% /callout %}`,
};
